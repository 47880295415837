import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import React, { useContext } from "react";
import { toast } from "react-hot-toast";
import {
  FaEnvelope,
  FaFacebookF,
  FaGithub,
  FaInstagram,
  FaLinkedinIn,
  FaSnapchat,
  FaThreads,
  FaXTwitter,
} from "react-icons/fa6";
import { userContext } from "../../Contexts/userContext";
import { db } from "../../backend/firebaseConfig";
const Social = () => {
  const contextData = useContext(userContext);
  const updateValueHandler = async () => {
    toast.loading("Updating Links");
    try {
      const updateRef = doc(db, "Link Forests", contextData.data.userInfo.id);
      await updateDoc(updateRef, {
        socialLinks: contextData.data.socialLinks,
        updatedTime: serverTimestamp(),
      });
      toast.dismiss();
      toast.success("Social Links Updated!");
    } catch (error) {
      toast.dismiss();
      console.error(error);
      toast.error("Something Went Wrong!");
    }
  };

  const userNameExtract = (link) => {
    let result = link?.endsWith("/")
      ? link.split("/").reverse()[1]
      : link.split("/").reverse()[0];

    return result;
  };

  const SocialLinkFieldData = {
    instagram: {
      icon: (
        <FaInstagram className="md:mx-2 mx-1 p-[2px] text-[28px] md:text-[32px] text-slate-800" />
      ),
      link: "https://www.instagram.com/",
    },
    linkedin: {
      icon: (
        <FaLinkedinIn className="md:mx-2 mx-1 p-[2px] text-[28px] md:text-[32px] text-slate-800" />
      ),
      link: "https://www.linkedin.com/in/",
    },
    github: {
      icon: (
        <FaGithub className="md:mx-2 mx-1 p-[2px] text-[28px] md:text-[32px] text-slate-800" />
      ),
      link: "https://www.github.com/",
    },
    twitter: {
      icon: (
        <FaXTwitter className="md:mx-2 mx-1 p-[2px] text-[28px] md:text-[32px] text-slate-800" />
      ),
      link: "https://www.x.com/",
    },
    facebook: {
      icon: (
        <FaFacebookF className="md:mx-2 mx-1 p-[2px] text-[28px] md:text-[32px] text-slate-800" />
      ),
      link: "https://www.facebook.com/",
    },
    threads: {
      icon: (
        <FaThreads className="md:mx-2 mx-1 p-[2px] text-[28px] md:text-[32px] text-slate-800" />
      ),
      link: "https://www.threads.net/",
    },
    email: {
      icon: (
        <FaEnvelope className="md:mx-2 mx-1 p-[2px] text-[28px] md:text-[32px] text-slate-800" />
      ),
      link: "abc@gmail.com",
    },
    snapchat: {
      icon: (
        <FaSnapchat className="md:mx-2 mx-1 p-[2px] text-[28px] md:text-[32px] text-slate-800" />
      ),
      link: "https://www.snapchat.com/add/",
    },
  };

  return (
    <div className="flex justify-center items-center flex-col">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
        {Object.keys(contextData.data.socialLinks).map((item) => {
          return (
            <div className="flex justify-start items-start w-full border rounded-md">
              <div className="bg-slate-50 h-full flex justify-center items-center px-2 py-4">
                {SocialLinkFieldData[item].icon}
              </div>
              <div className="mx-2 py-2 w-full">
                <p className="text-slate-400 text-sm font-medium">
                  {SocialLinkFieldData[item].link}
                </p>
                <input
                  type="text"
                  className="w-full py-1 outline-none border-b mt-1 text-sm"
                  value={userNameExtract(contextData.data.socialLinks[item])}
                  onChange={(e) =>
                    contextData.setData({
                      ...contextData.data,
                      socialLinks: {
                        ...contextData.data.socialLinks,
                        [item]: e.target.value
                          ? item !== "email"
                            ? SocialLinkFieldData[item].link + e.target.value
                            : e.target.value
                          : e.target.value,
                      },
                    })
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
      <button
        className="text-slate-950 my-8 font-Montserrat px-2 md:px-3 py-2 text-sm md:text-base font-semibold ease-linear hover:ease-linear transition-all hover:transition-all duration-300 hover:duration-300 hover:shadow-lg hover:shadow-emerald-600/50 bg-emerald-400 to-emerald-300 shadow-md shadow-emerald-400/50 rounded-md"
        onClick={updateValueHandler}
      >
        Update Social Links
      </button>
    </div>
  );
};

export default Social;
